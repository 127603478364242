<template>
	<div class="list-banners">

		<table-list
			:items="banners"
			:loading="loading"
		>
			<template slot="header">
				<span
					:class="`banners-header-item banners-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					{{ col }}
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<router-link
					:class="`banners-item banners-item--${key}`"
					:key="`item_${key}`"
					:to="{ name: 'banner', params: { bannerId: item.bannerId } }"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item, key))"
				>
				</router-link>
			</template>
		</table-list>
	</div>
</template>

<script>
import { format, parseISO, formatDistanceToNowStrict } from 'date-fns'
import { get } from 'lodash'
import { BannerStatus } from '@/lib/enums'

import TableList from '@/components/TableList'

export default {
	name: 'ListBanners',
	components: {
		TableList,
	},
	props: {
		banners: {
			type: Array,
			default: () => ([]),
		},
		loading: Boolean,
	},
	data: () => ({
		columns: {
			title: 'Title',
			subtitle: 'Subtitle',
			url: 'Link',
			button: 'Button',
			dateCreated: 'Created',
			status: 'Status',
		},
	}),
	methods: {
		transform(key, value) {
			switch(key) {
				case 'dateCreated':
					try {
						const dt = parseISO(value)
						value = `<span title="${format(dt, 'Pp')}">${formatDistanceToNowStrict(dt, { addSuffix: true})}</span>`
					} catch (error) {
						console.warn(error)
					}
					return value
				case 'status':
					return BannerStatus[value]
				case 'url':
					return `<a href="${value}" target="_blank" rel="noreferrer" title="${value}">Link <ion-icon name="open-outline"></ion-icon></a>`
				default:
					break
			}

			return value
		},
		get,
	},

}
</script>

<style scoped lang="scss">
.list-banners {
	&__heading {
		@include modules.gutter('padding-left');
		@include modules.gutter('padding-right');
	}

	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('background', 'medium');
			display: grid;
			grid-template-columns: repeat(5, 1fr) minmax(140px, auto);

			@include modules.media-query('phone') {
				grid-template-columns: 1fr 1fr;
			}
		}
	}

	.banners-header-item {
		@include modules.fontSize(14px);
		font-weight: 600;
		padding: 1em 1.5em;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.banners-item {
		color: modules.color_('text', 'dark');
		font-weight: 500;
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.5em 1em;
		}
	}
}
</style>
