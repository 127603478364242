<template>
	<div class="partial partial--banners banners">
		<div class="partial__heading">
			<ion-icon name="flag" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Manage Banners</h1>

			<div class="partial__actions">
				<router-link :to="{name: 'banner' }" class="button">
					<div class="mdc-button__ripple"></div>
					<ion-icon name="add-circle-outline" class="mdc-button__icon" aria-hidden="true"></ion-icon>
					<span class="mdc-button__label">Add Banner</span>
				</router-link>
			</div>
		</div>

		<div class="partial__body banners__body">
			<list-banners :banners="banners" :loading="loading" />
		</div>

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import Modal from '@/components/Modal'
import ListBanners from '@/components/ListBanners'

export default {
	name: 'PartialBanners',
	components: {
		ListBanners,
		Modal,
	},
	metaInfo: () => ({
		title: 'Banners',
	}),
	async mounted() {
		await this.$store.dispatch('banner/list')
	},
	computed: {
		banners() {
			return this.$store.getters['banner/banners']
		},
		loading() {
			return this.$store.getters['misc/loading']
		},
		modalVisible() {
			return this.$route.name == 'banner'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
	},
	methods: {
		hideModal(clicked) {
			this.$router.replace({ name: 'banners' })
		},
	},
}
</script>

<style scoped lang="scss">

</style>
