<template>
	<form class="form form--banner form-banner" @submit.prevent.stop="submitForm(fields)">
		<div class="form__loader" v-if="loading || isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-1">
				<text-field class="form__field" label="Title" v-model="fields.title" :required="true" :maxlength="50" />
				<text-field class="form__field" label="Subtitle" v-model="fields.subtitle" :required="false" :maxlength="25" />
				<div class="form-row form-row--col-2">
					<text-field class="form__field" type="url" label="Button Link" placeholder="https://" v-model="fields.url" :required="false" />
					<text-field class="form__field" label="Button Label" v-model="fields.button" :required="false" :maxlength="25" />
				</div>
				<text-area class="form__field" :rows="5" label="Description" v-model="fields.description" :maxlength="250" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="bannerStatus" />
				<h2 class="form-section__title">Image</h2>
				<div class="form-row form-row--col-2">
					<img :src="banner.image" v-if="banner.image" class="form-banner__image" />

					<label class="form-banner__upload" :class="{'form-banner__upload--loading': isImageLoading}">
						<loading-spinner v-if="isImageLoading" class="form-banner__upload-loading" />
						Upload Image (.png, .jpg) <br />
						<input type="file" name="logo" id="logo" @change="toUpload = $event.target.files[0]" accept="image/png,image/jpeg">
					</label>
				</div>
			</div>
		</div>

		<div class="alert alert--fail" v-if="error" v-html="error"></div>

		<div class="form__actions form__actions--horizontal">
			<a-button class="button--primary form__action" type="submit" :disabled="loading || isLoading">
				{{ fields.bannerId ? `Update` : `Create` }} Banner
			</a-button>

			<button class="button button--critical form__action" type="button" :disabled="loading || isLoading" @click="remove(fields)" v-if="fields.bannerId && fields.status != 'removed'">
				Remove Banner
			</button>
		</div>
	</form>
</template>

<script>
import { get, pick } from 'lodash'

import AButton from '@/components/AButton'
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'
import { BannerStatus } from '@/lib/enums'

export default {
	name: 'FormBanner',
	components: {
		AButton,
		Checkbox,
		LoadingSpinner,
		SelectField,
		TextArea,
		TextField,
	},
	props: {
		loading: Boolean,
		banner: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
		},
		error: null,
		fields: {},
		toUpload: null,
		isLoading: false,
		isImageLoading: false,
	}),
	computed: {
		bannerStatus() {
			let status = []
			for (let key in BannerStatus) {
				status.push({
					value: key,
					label: BannerStatus[key],
				})
			}

			return status
		},
	},
	methods: {
		submitForm(fields) {
			if (fields.bannerId) {
				this.isLoading = true
				this.$store.dispatch('banner/update', { bannerId: fields.bannerId, data: fields })
					.then(() => {
						this.$notice(`Banner updated!`)

						if (this.toUpload) {
							this.uploadImage(this.toUpload)
						}
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			} else {
				this.isLoading = true
				this.$store.dispatch('banner/create', fields)
					.then(() => {
						this.$notice(`Banner created!`)

						if (this.toUpload) {
							this.uploadImage(this.toUpload)
						}
					})
					.catch((error) => {
						this.$notice(`ERROR: ${error.message || error}`)
					})
					.finally(() => {
						this.isLoading = false
					})
			}
		},
		remove(fields) {
			if (!fields.bannerId) return

			this.$confirm(`Are you sure you want to remove "${fields.title}"?`, () => {
				this.$store.dispatch('banner/remove', fields.bannerId ).then(() => {
					this.$notice(`Banner removed!`)
					this.$router.back()
				}).catch(error => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
			})
		},
		uploadImage(file) {
			const formData = new FormData()
			formData.append('file', file)
			this.isImageLoading = true
			this.$store.dispatch('banner/uploadImage', { bannerId: this.fields.bannerId, data: formData })
				.then(() => {
					this.$notice(`Banner image uploaded!`)
				})
				.catch(error => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isImageLoading = false
				})
		},
	},
	watch: {
		banner: {
			immediate: true,
			handler: function (bannerProp) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...pick(bannerProp, ['bannerId', 'title', 'subtitle', 'button', 'createdBy', 'createdByName', 'dateCreated', 'dateModified', 'modifiedBy', 'modifiedByName', 'url', 'description','image','status']),
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">
.form-banner {
	&__image {
		display: block;
		max-width: 250px;
	}
}
</style>
