<template>
	<div class="modal modal--banner modal-banner">
		<div class="modal__loading" v-if="isLoading">
			<loading-spinner />
		</div>
		<div class="modal-banner__form">
			<form-banner :banner="banner" />
		</div>
	</div>
</template>

<script>
import AButton from '@/components/AButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import FormBanner from '@/components/FormBanner.vue'

export default {
	name: 'ModalBanner',
	components: {
		AButton,
		FormBanner,
		LoadingSpinner,
	},
	data: () => ({
		isLoading: false,
	}),
	async mounted() {
		this.loading = true
		if (this.$route.params.bannerId) {
			await this.$store.dispatch('banner/get', this.$route.params.bannerId)
		}
		this.loading = false
	},
	destroyed() {
		this.$store.dispatch('banner/unset')
	},
	computed: {
		banner() {
			return this.$store.getters['banner/banner']
		},
	},
	metaInfo: () => ({
		title: `Banner Editor`,
	}),

}
</script>

<style scoped lang="scss">
</style>
