<template>
	<div class="modal modal--salesforce modal-salesforce">
		<div class="modal__loading" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="modal-salesforce__actions">
			<button class="button button--unelevated button--primary" @click="salesforceUsers()" :disabled="isLoading">Sync Users</button>
			<button class="button button--unelevated button--primary" @click="salesforceOrgs()" :disabled="isLoading">Sync Organizations</button>
		</div>

		<div class="modal-salesforce__results">
			<ul class="list modal-salesforce__results-list">
				<li class="list__item list__item--title">Matched ({{ results.matched.length || 0 }})</li>
				<li class="list__item"
					v-for="(item, key) in results.matched"
					:key="`matched_${key}`">
					<router-link :to="{name: type, params: {uid: item.idx, orgId: item.idx }}" target="_blank">
						{{ item.name }}
					</router-link><br />
					<template v-if="item.email">{{ item.email }}<br /></template>
					<template v-if="item.city">{{ item.city }}<br /></template>
					<small><a :href="item.salesforceLink" target="salesforce">{{ item.salesforceId }}</a></small>
				</li>
			</ul>
			<ul class="list modal-salesforce__results-list">
				<li class="list__item list__item--title">
					Unmatched ({{ results.unmatched.length || 0 }})
					<button class="button button--secondary button--small" @click="exportUnmatched(results.unmatched)" :disabled="!results.unmatched.length">export</button>
				</li>
				<li class="list__item"
					v-for="(item, key) in results.unmatched"
					:key="`unmatched_${key}`">
					<router-link :to="{name: type, params: {uid: item.idx, orgId: item.idx }}" target="_blank">
						{{ item.name }}
					</router-link><br />
					<template v-if="item.email">{{ item.email }}<br /></template>
					<template v-if="item.city">{{ item.city }}</template>
				</li>
			</ul>
			<ul class="list modal-salesforce__results-list">
				<li class="list__item list__item--title">Errors ({{ results.errors.length || 0 }})</li>
				<li class="list__item"
					v-for="(item, key) in results.errors"
					:key="`errors_${key}`">
					<router-link :to="{name: type, params: {uid: item.idx, orgId: item.idx }}" target="_blank">
						{{ item.name }}
					</router-link><br />
					<template v-if="item.email">{{ item.email }}<br /></template>
					<template v-if="item.city">{{ item.city }}<br /></template>
					{{ item.error }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import AButton from '@/components/AButton'
import LoadingSpinner from '@/components/LoadingSpinner'
import { flatten, forceDownload } from '@/lib/utils'

const SALESFORCE_URL = process.env.SALESFORCE_URL || process.env.VUE_APP_SALESFORCE_URL

const _RESULTS = {
	matched: [],
	unmatched: [],
	errors: [],
}

export default {
	name: 'ModalSalesforce',
	components: {
		AButton,
		LoadingSpinner,
	},
	data: () => ({
		isLoading: false,
		results: _RESULTS,
		type: 'user',
	}),
	methods: {
		async salesforceUsers() {
			this.type = 'user'
			this.isLoading = true
			this.results = _RESULTS
			this.$store.dispatch('tools/salesforceUsers')
				.then((res) => {
					res.matched = res.matched.map((r) => {
						r.salesforceLink = `${SALESFORCE_URL}/lightning/r/Contact/${r.salesforceId}/view`
						return r
					})
					this.results = res
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		async salesforceOrgs() {
			this.type = 'organization'
			this.isLoading = true
			this.results = _RESULTS
			this.$store.dispatch('tools/salesforceOrgs')
				.then((res) => {
					res.matched = res.matched.map((r) => {
						r.salesforceLink = `${SALESFORCE_URL}/lightning/r/Account/${r.salesforceId}/view`
						return r
					})
					this.results = res
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})
		},
		exportUnmatched(unmatched) {
			const _keys = []
			const _vals = []

			let csv = ''
			if (unmatched.length) {
				let rows = []
				let header =[]
				unmatched.forEach(row => {
					let flat = flatten(row)
					let head = Object.keys(flat)
					header = [...new Set([...header, ...head])]
					rows.push(flat)
				})

				csv = [
					header,
					...rows.map(row => header.map(f => {
						return JSON.stringify(row[f])
					}))
				].join(`\r\n`)
			}
				// return csv
			// const exported = await this.$store.dispatch('user/report', {key: keys, value: vals, op: keys && vals ? 'array-contains' : ''})

			try {
				forceDownload(csv, `Salesforce Sync Export.csv`)
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			}
		},
	},
	metaInfo: () => ({
		title: `Salesforce Sync`,
	}),

}
</script>

<style scoped lang="scss">
.modal-salesforce {
	&__actions {
		@include modules.gutter('padding');
		@include modules.gutter('grid-column-gap');
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	&__results {
		@include modules.gutter('padding');
		@include modules.gutter('grid-column-gap', 0.25);
		border-top: 1px solid modules.color_('border');
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		width: 100%;

		&-list {
			list-style-type: none;
			margin: 0;
			// padding: 0;

			.list__item {
				border-bottom: 1px solid rgba(modules.color_('border'), 0.5);
				margin-bottom: 0.5rem;
				padding-bottom: 0.5rem;

				&--title {
					font-weight: 600;
					text-align: center;
					min-height: 3rem;
				}
			}
		}
	}
}
</style>
