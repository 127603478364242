<template>
	<div class="list list--tools list-tools">
		<card
			class="list-tools__item"
			:key="`my_${key}`"
			v-for="(item, key) in tools"
		>
			<div class="list-tools__item-logo">
				<img :src="item.logo" v-if="item.logo" />
				<ion-icon :name="item.icon" v-if="item.icon" />
			</div>
			<div class="list-tools__item-title"> {{ item.name }}</div>
			<router-link class="list-tools__item-link" :to="item.link">Launch Tool</router-link>
		</card>
	</div>
</template>

<script>
import Card from '@/components/Card'

export default {
	name: 'ListTools',
	components: {
		Card,
	},
	props: {
		tools: {
			type: Array,
			default: () => ([]),
		},
	},
}
</script>

<style scoped lang="scss">
@use '@material/elevation';

.list-tools {
	@include modules.gutter('grid-column-gap');
	@include modules.gutter('grid-row-gap');

	display: grid;
	grid-template-columns: repeat(2, 1fr);

	@include modules.media-query('phone') {
		grid-template-columns: 1fr;
	}

	@include modules.media-query('tablet') {
		grid-template-columns: repeat(2, 1fr);
	}

	@include modules.media-query-min-width('desktop-sm') {
		grid-template-columns: repeat(3, 1fr);
	}

	&__item {
		// @include elevation.elevation(20, rgba(0, 0, 0, 0.15), -0.1);
		@include modules.gutter('padding-top');

		align-items: center;
		border-radius: modules.$border-radius-lg;
		display: flex;
		flex-direction: column;

		&-logo {
			@include modules.gutter('margin-bottom', .25);
			ion-icon {
				color: modules.color_('primary');
				@include modules.fontSize(70px);
			}

			img {
				height: 70px;
				width: 70px;
				color: modules.color_('primary');
			}
		}

		&-title {
			@include modules.gutter('padding-bottom');
			@include modules.gutter('padding-left');
			@include modules.gutter('padding-right');
			@include modules.fontSize(24px);
			font-weight: 500;
			line-height: 1.35;
			text-align: center;
		}

		&-link {
			border-top: 1px solid rgba(modules.color_('border'), 0.5);
			color: modules.color_('primary');
			@include modules.fontSize(14px);
			font-weight: 600;
			letter-spacing: 2.2px;
			text-align: center;
			text-decoration: none;
			text-transform: uppercase;
			transition: all 150ms linear;
			padding: 1.5rem;
			width: 100%;

			&:hover {
				color: modules.color_('primary', 'light');
			}
		}
	}
}
</style>
